

.multimarketMianWrap {
    position: relative;
    overflow: hidden;
  }
  
  .multimarketNoData {
    background: #fff;
    border: 1px solid #7e97a7;
    border-radius: 10px;
    color: #7e97a7;
    font-size: 20px;
    margin: 25px 9.5px;
    text-align: center;
    padding: 15px;
  }
  
  .multimarketNoData h3 {
    margin-bottom: 0;
    color: #7e97a7;
    border-bottom: 1px solid #e0e6e6;
  }
  