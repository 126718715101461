.blinking-container {
    display: inline-block;
    text-align: center;
}

.blinking-text {
font-size: 10px; /* Adjust font size as needed */
font-weight: bold;
color: red; /* Change to your preferred color */
animation: blink 1s infinite;
}

@keyframes blink {
0%, 100% {
    opacity: 1;
}
50% {
    opacity: 0;
}
}