/* HTML: <div className="loader"></div> */
.loaderWrapper{
  position: fixed;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0,0,0,0.5);
  opacity: .8;
  z-index: 99999;
  top: 0;
  left: 0;
}
.loader {
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side,#ED303C 94%,#0000),
      radial-gradient(farthest-side,#3B8183 94%,#0000),
      radial-gradient(farthest-side,#FAD089 94%,#0000),
      radial-gradient(farthest-side,#FF9C5B 94%,#0000),
      #ED303C;
    background-size: 105% 105%;
    background-repeat: no-repeat;
    animation: l5 2s infinite; 
  }
  @keyframes l5 {
    0%  {background-position: 50% -50px,-40px 50%, 50% calc(100% + 50px),calc(100% + 50px) 50%}
    20%,
    25% {background-position: 50% -50px,-50px 50%, 50% calc(100% + 50px),50% 50%}
    45%,
    50% {background-position: 50% -50px,-50px 50%, 50% 50%              ,50% 50%}
    75%,
    75% {background-position: 50% -50px, 50%  50%, 50% 50%              ,50% 50%}
    95%,
    100%{background-position: 50%  50% , 50%  50%, 50% 50%              ,50% 50%}
  }