@media screen and (min-width:1200px) and (max-width:1305px) {
  .bottom-header li {
    padding: 6px 8px;
    font-size: 12px;
    font-weight: 700;
  }
}

@media screen and (min-width:992px) and (max-width:1800px) {
  .score-tab {
    left: 0px;
  }

  .col-lg-2.score-tab {
    /* width: 17.50%; */
    width: 19.40%;
    left: 0;
  }

  .score-tabcontent {
    margin: 0px 17.60%;
    width: 58%;
  }

}

@media screen and (min-width:992px) and (max-width: 1056px) {
  header.dream-header {
    height: 139px;
  }

  .score-sec {
    margin-top: 139px;
  }

  .score-tab {
    top: 91px !important;
  }
}

@media screen and (max-width:1199px) {

  /* HEADER CSS */
  .bottom-header li {
    padding: 6px 7px;
  }

  .dream-header .container-fluid {
    padding: 0px;
  }

  .header-right ul {
    gap: 10px
  }

  .header-right ul li:nth-child(2) input {
    width: 40% !important;
  }

  .header-right ul li:nth-child(2) {
    width: 49%;
    justify-content: end;
  }

  .bottom-header a {
    font-size: 13px;
  }

  .header-right ul li input {
    outline: none;
    border-radius: 5px;
    border: 0px;
    width: 84%;
  }

  .header-left img {
    width: 168px;
  }



  /* SCORE CSS */

  .inner-data .row:nth-child(1) ul li span {
    width: 66px;
  }

  .inner-data-right ul li span:nth-child(1) {
    padding: 4px 7px;
    font-size: 14px;
  }

  .inner-data-right ul li span:nth-child(2) {
    padding: 4px 7px;
    font-size: 14px;
  }

  .inner-data-center ul li p {
    width: 30px;
    font-size: 11px;
    padding: 2px 2px;
  }

  .inner-data-center ul {
    gap: 5px;
    justify-content: center;
  }

  .inner-data-center ul li {
    margin: 0;
  }

  .bottom-header a {
    color: #000000;
    transition: 0.3s all ease-in-out;
    font-size: 11px;
  }

  .inner-data-right ul li {
    margin-left: 4px;
  }

  .inner-data-right ul li span:nth-child(2),
  .inner-data-right ul li span:nth-child(1) {
    width: auto;
  }

  .inner-data-right {
    width: 49%;
  }

  .inner-data-center {
    width: 27%;
    padding: 0 20px;
  }

  .inner-data-left {
    width: 24%;
  }

  /* SIDE NEV CSS */
  .nav-link2 {
    padding: 10px 20px;
  }


  /* detail Page CSS */

  .bet-row-suspended-left,
  .bet-row-suspended-l {
    width: 30%;
  }

  .bet-row-suspended-right,
  .bet-row-suspended-r {
    width: 70%;
  }

  .bet-chart {
    gap: 5px;
  }


  /* LOGIN HEADER CSS */

  li.my-accoutn.d-flex {
    width: 178px;
    font-size: 15px;
  }

  .reload a {
    padding: 11px 20px;
  }

  .drop-my-account {
    width: 33%;
  }

  /* HORSE PAGE CSS */

  .horse-over-flow .bet-table-chart-p span {
    font-size: 12px;
  }

  /* POP_UP CSS */
  .pop-up-sec-work.position-relative {
    width: 50%;
  }

  /* IN PLAY PAGE CSS */

  /* IN PLAY BET CHART CSS */

  .inplay-maintab {
    width: 18%;
  }

  /* MY PROFILE PAGE CSS */

  .Details-op2 {
    width: 70%;
  }

  .Details-op {
    width: 30%;
  }

  .rolling-commission-formsec,
  .rolling-commission-sec2 {
    width: 500px;
  }


  /* ACTIVITY LOG PAGE CSS */

  .activity-log-sec2 th {
    min-width: 160px;
  }


  /* BETS HISTORY CSS */

  .account-statment-tables table th {
    min-width: 156px;
  }

}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {

  .mobile-menu-before::before {
    top: -3.26667vw;
    height: 13.53333vw;
  }

}

@media screen and (max-width:991px) {

  /* FOOTER CSS */

  .footer {
    padding: 20px 0px 140px;
  }

  /* HEADER CSS  */
  header.dream-header {
    height: 69px;
  }

  .header-right,
  .header-left {
    width: 50%;
    padding: 0;
  }

  .bottom-header li {
    padding: 3px 10px 3px;
  }

  .bottom-header {
    display: none;
  }

  .header-right form {
    width: 100%;
  }

  .header-right form input {
    display: none;
  }


  /* LOGIN HEADER CSS */

  .my-accoutn-btn {
    display: none !important;
  }

  .login-input input {
    display: none;
  }

  .search {
    display: none;
  }

  a.settings {
    display: block;
  }

  li.my-accoutn.d-flex {
    font-size: 15px;
    justify-content: end;
    width: 118px;
  }

  .reload {
    margin: 0px;
  }

  .reload a {
    box-shadow: inset 0 1px 4px 0 rgba(255, 255, 255, .4);
    border-radius: 1.06667vw;
    background: linear-gradient(-180deg, #000000 0%, #000000 100%);
  }

  /* SCORE CSS */

  .score-sec .container-fluid {
    padding: 0px;
  }

  .table-container {
    max-height: 340px;
    overflow-y: scroll;
  }

  .table-container::-webkit-scrollbar {
    display: none;
  }

  .score-sec {
    margin-top: 76px;
  }

  .bet-table-chart tbody tr td:nth-child(3),
  .bet-table-chart tbody tr td:nth-child(4),
  .bet-table-chart tbody tr td:nth-child(5),
  .bet-table-chart thead {
    display: none;
  }

  .bm-s-f {
    justify-content: end !important;
  }

  .bet-table-chart tr td:nth-child(1) {
    width: 35%;
  }

  .bet-table-chart tr td:nth-child(2) {
    width: 16%;
  }

  .tab-content .row:nth-child(1) .inner-data-left,
  .tab-content .row:nth-child(1) .inner-data-right,
  .tab-content .row:nth-child(1) .inner-data-center {
    display: none;
  }

  .inner-data-center ul li p {
    width: 40px;
    font-size: 14px;
    padding: 2px 2px;
  }

  .over-flow li {
    margin-left: 10px;
  }

  .over-flow li:nth-child(1) {
    margin-left: 0px;
  }

  .over-flow-ul li:nth-child(n+4) {
    display: block;
  }

  .over-flow ul li button {
    background: none;
    color: #000;
    align-items: center;
    padding: 6px 10px;
    font-size: 17px;
    font-weight: 700;
    outline: none;
    border: 0px;
    font-size: 14px;
  }

  .nav-item svg {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .mobile-nav ul li:nth-child(9) button.active svg,
  .mobile-nav ul li:nth-child(8) button.active svg {
    filter: invert(1);
  }

  .over-flow li button {
    width: auto;
    display: flex;
  }

  .over-flow {
    overflow-x: scroll;
  }


  .score-tab {
    display: none;
  }

  .second-search {
    display: block;
  }

  .tab-content .inner-data .row {
    width: 100%;
    justify-content: center;
  }

  .highlights h4 {
    display: none;
  }

  .inner-data-right ul li {
    display: none !important;
  }

  .inner-data-right ul li:nth-child(4) {
    display: block !important;
  }

  .inner-data-right ul {
    gap: 5px;
    justify-content: end;
  }

  .inner-data-right {
    width: 29%;
  }

  .inner-data-left {
    width: 40%;
  }

  .inner-data-center {
    width: 31%;
  }

  .inner-data-left p span {
    font-size: 18px;
    display: block;
  }

  .score-tabbets {
    display: none;
  }

  .table-name {
    display: none;
  }

  .score-tabcontent-mobile {
    display: block;
  }

  /* .score-tab-group {display: none;} */

  /* IN PLAY PAGE CSS */

  /* IN PLAY BET CHART CSS */

  .inplay-tennis-sec {
    text-align: center;
  }

  .over-flow .inplay-ul {
    padding: 0 10px;
  }

  .new2-inplay table td:nth-child(n+2) {
    display: none;
  }

  .inplay-maintab {
    padding: 10px;
    width: 14%;
  }

  .over-flow .inplay-ul {
    padding-bottom: 10px;
  }

  .new2 {
    padding: 10px 0px 0px 0px;
  }

  /* HORSE PAGE CSS */

  .over-flow::-webkit-scrollbar {
    display: none;
  }

  .bulking-number-l {
    margin-bottom: 16px;
  }

  .horse-over-flow .bet-table-chart-p span {
    font-size: 12px;
  }

  .row.score-sec-row {
    width: 100%;
    margin: auto;
  }

  .score-tabcontent {
    padding: 0;
    margin: 0px;
    width: 100%;
  }

  .search {
    display: none !important;
  }

  .patti-images {
    margin: auto;
  }



  /* MOBILE NAV CSS */

  .mobile-menu-before::before {
    position: absolute;
    top: -4.26667vw;
    left: 0;
    content: "";
    background: var(--mobile-before);
    z-index: -1;
    width: 100%;
    height: 17.53333vw;
    pointer-events: none;
    display: block;
    clip-path: polygon(99.973% 24.242%, 100% 24.242%, 100% 100%, 0% 100%, 0% 24.242%, .027% 24.242%, .027% 24.242%, .885% 23.318%, 2.866% 21.335%, 5.893% 18.565%, 9.891% 15.281%, 14.783% 11.756%, 20.494% 8.261%, 26.947% 5.068%, 34.066% 2.451%, 41.776% .681%, 50% .03%, 50% 12.152%, 50% .03%, 50% .03%, 58.224% .681%, 65.934% 2.451%, 73.053% 5.068%, 79.506% 8.261%, 85.217% 11.756%, 90.109% 15.281%, 94.107% 18.565%, 97.134% 21.335%, 99.115% 23.318%, 99.973% 24.242%);
  }

  .account-statment-tables table th {
    min-width: 180px;
  }

  .account-statment-tables table th i {
    margin-left: 40px;
  }

  /* DETAIL PAGE CSS */

  .score-sec-row2 {
    margin-bottom: 110px;
  }

  .bet-row-suspended2.d-flex {
    padding: 0 0px 0px 7px;
  }

  .bet-row-suspended-r li:nth-child(1) img {
    text-align: right;
    padding-right: 10px;
  }

  section.score-sec ul .i-icon {
    text-align: right !important;
    display: flex;
    justify-content: end;
  }

  /* Login Page CSS */
  .login-mobile {
    width: 100%;
    justify-content: end;
  }



}

@media screen and (max-width:767px) {


  header.dream-header {
    height: 69px;
  }

  .header-work.row.align-items-center {
    width: 100%;
    margin: auto;
  }

  /* Detail Page CSS */

  .bet-chart {
    gap: 2px;
    justify-content: end;
  }

  .bet-chart li {
    display: none;
  }



  .bet-row-suspended-r ul li:nth-last-child(1) {
    display: none;
  }

  .bet-row-suspended-r ul li {
    width: 69%;
  }

  .bet-row-suspended-r ul li img {
    display: block;
  }

  .bet-row-suspended-r ul li:nth-child(1) p {
    display: none;
  }

  .bet-table-chart tbody tr p span {
    font-size: 23px;
  }

  /* IN PLAY PAGE CSS */

  /* IN PLAY BET CHART CSS */

  .inplay-maintab {
    padding: 23px;
    width: 33%;
  }

  .over-flow .inplay-ul {
    justify-content: center;
  }

  /* HORSE PAGE CSS */
  .horse-over-flow .bet-table-chart-p span {
    font-size: 12px;
  }

  /* MOBILE SCREEN SEARCH BAR CSS */
  .fix-search-bar {
    width: 90px;
  }


  /* ROLLING COMMISSION PAGE CSS */
  .my-profile-select:nth-child(3) {
    margin-top: 15px;
  }


  /* MOBILE NAVE CSS */


  .mobile-menu li a p {
    font-size: 17px;
    margin-bottom: 0px;
  }


  /* SPORTS PAGE CSS */

  .all-sports-link li a i {
    font-size: 30px;
  }

  .all-sports-link li p {
    font-size: 18px;
  }

  .all-sports-link li a svg {
    width: 29px;
    height: 29px;
  }

  /* SPORTS PAGE CSS */
  .sports-work-heading p,
  .sports-work-heading2 p {
    font-size: 24px;
    padding: 8px 10px;
  }

  .all-sports-link li {
    border-left: solid 1px var(--sports-border);
  }

  .sports-workstart .accordion-item h2 button,
  .accordion-inner li {
    font-size: 24px !important;
  }

  .my-profile-select:nth-child(2) input,
  .my-profile-select:nth-last-child(1) button {
    margin-top: 15px !important;
  }

}

@media screen and (max-width:575px) {

  header.dream-header {
    height: 66px;
    width: 100%;
  }




  /* SCORE CSS */
  .score-sec {
    margin-top: 66px;
    width: 100%;
  }

  .row.score-sec-row {
    width: auto;
  }

  .inner-data::-webkit-scrollbar {
    display: none;
  }


  .inner-data-center ul {
    gap: 3px;
  }

  .inner-data-center ul li p {
    width: 26px;
    font-size: 10px;
  }

  .inner-data-center {
    width: 42%;
  }

  .inner-data-right {
    width: 10%;
  }

  .inner-data-left {
    width: 48%;
  }

  .inner-data-left p span {
    font-size: 14px;
    font-weight: 500;
  }

  .nav-link2 {
    padding: 10px 20px;
  }

  .highlights h4 {
    font-size: 14px;
  }

  .header-right,
  .header-left {
    width: 50%;
    padding: 0;
  }

  .log-in button {
    padding: 7px 10px;
    font-size: 13px;
  }

  .over-flow li button {
    width: auto;
  }

  .over-flow ul li button {
    font-size: 14px;
  }

  .nav-item svg {
    width: 20px;
    height: 20px;
    margin-right: 2px;
  }



  .nav-link2.active {
    border-radius: 5px 5px 0px 0px;
  }

  .patti-images {
    width: 30%;
    padding-inline: 0px;
  }

  ul#myTab {
    padding: 5px 40px 0px 3px;
  }

  /* Detail Page CSS */



  .score-tabcontent {
    padding: 0px;
  }

  .bet-chart li {
    width: 29% !important;
  }

  .bet-sec .bet-sec-ods li:nth-child(2) {
    margin: 5px 0 5px 10px;
    align-items: center;
  }

  .bet-row-suspended-work {
    padding: 0px 2px;
  }


  .bet-row-suspended-r li:nth-child(1) {
    width: 30%;
  }

  .bet-row-suspended-r ul li:nth-child(2) {
    width: 66%;
  }

  .bet-row-suspended-r ul {
    justify-content: end !important;
  }

  .bet-row-suspended2.d-flex {
    border-bottom: 1px solid;
    padding: 0 0px 0px 7px;
  }

  .bet-row-suspended-box {
    padding: 2px 10px;
  }

  .sceond-suspend {
    padding-top: 8px;
    font-size: 12px;
  }





  .score-tabcontent-mobile .nav .nav-item button {
    height: 34px;
    font-weight: 700;
    font-size: 12px;
    padding: 0 11px;
  }

  .score-tabcontent-mobile .nav .nav-item button.active {
    height: 34px;
  }

  .score-tabcontent-mobile .nav {
    margin: 5px 0px;
  }

  /* LOGIN HEADER CSS */



  .reload a {
    padding: 6px 12px;
  }

  .bets-header div {
    width: 83px;
    padding: 14px 0;
    height: 38px;
    border-radius: 4px;
  }

  .bets-header p {
    font-size: 12px;
  }

  li.main-pit strong {
    font-size: 9px;
  }

  .dream-header .container-fluid {
    padding: 0 10px;
  }

  .header-left img {
    width: 120px;
  }

  li.my-accoutn.d-flex {
    width: 88px;
  }

  /* IN PLAY PAGE CSS */

  /* IN PLAY BET CHART CSS */

  .over-flow .inplay-ul {
    padding: 5px 5px 5px 5px;
    margin: 0px;
  }

  .new2 {
    padding: 0px;
  }

  .inplay-maintab {
    padding: 5px 2px;
  }

  /* HORSE PAGE CSS */
  .horse-over-flow .bet-table-chart-p span p {
    font-size: 12px;
  }

  /* PATTI SEC CSS */

  .patti-images2 p {
    font-size: 10px;
  }

  .patti-images2 {
    margin-bottom: 20px;
  }



  /* MOBILE SCREEN SEARCH BAR CSS */
  .fix-search-bar {
    width: 70px;
  }

  .mobile-input input {
    width: 88%;
  }

  /* FOOTER CSS */

  .bottom-footer li {
    padding: 0px 11px 0px;
  }

  .bottom-footer li a {
    font-size: 13px;
  }


  /* ROLLING COMMISSION PAGE CSS */

  .my-profile-center {
    padding: 0 5px !important;
  }


  /* MY PROFILE PAGE CSS */

  .Details-op {
    width: 100%;
    border-bottom: 1px solid var(--commision-thead-border) !important;
    padding: 6px 14px;
  }

  .Details-op2 {
    width: 100%;
    padding: 6px 14px;
  }

  .prfile-account-fiels ul li {
    padding: 0px;
  }

  .rolling-commission-formsec,
  .rolling-commission-sec2 {
    width: 98%;
  }

  .rolling-commission-sec2 {
    margin-top: 11px;
  }

  /* ACCOUNT STATMENT PAGE CSS */

  .account-statment-tables table th {
    min-width: 134px !important;
  }

  .account-statment-tables table th i {
    margin-left: 29px;
  }

  .showing-content-right ul {
    justify-content: center;
  }

  .howing-content {
    text-align: center;
  }

  .account-lable {
    justify-content: center;
  }

  /* LOG IN PAGE CSS */
  .login-off-btn {
    padding: 18px 30px;
  }

  /* MOBILE NAV CSS  */

  .mobile-menu li a p {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .mobile-menu li {
    width: calc(100% / 5 - 0px / 5);
    padding: 5px 0 0px;
  }

  /* FOOTER CSS */

  .footer {
    padding: 20px 0px 100px;
  }

  /* SPORTS PAGE CSS */

  .all-sports-link li a svg {
    width: 19px;
    height: 19px;
  }

  .all-sports-link li p {
    font-size: 11px;
  }

  .all-sports-link li a i {
    font-size: 18px;
  }

  .all-sports-link li,
  .all-sports-link li:nth-child(1) {
    padding: 5px 6px;
  }

  .sports-work-heading p,
  .sports-work-heading2 p {
    font-size: 14px;
    padding: 4px 10px;
  }

  .sports-workstart .accordion-item h2 button,
  .accordion-inner li {
    font-size: 16px !important;
  }

  .pop-up-sec-work.position-relative {
    width: 97%;
  }

  section.score-sec.sports-work {
    margin-bottom: 89px;
  }

}

@media screen and (max-width:380px) {
  .login-mobile {
    width: 100%;
  }
}

@media screen and (min-width:400px) and (max-width:575px) {
  .bet-table-chart tbody tr p span {
    font-size: 17px;
  }
}

@media screen and (min-width:100px) and (max-width:400px) {
  .bet-table-chart tbody tr p span {
    font-size: 13px;
  }
}

.cricketSpecificUi .cricketS {
  width: 28px;
  height: 25px;
  border-radius: 5px;
  background-color: green;
  font-size: 14px;
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.cricketSpecificUi .cricketBm {
  width: 28px;
  height: 25px;
  border-radius: 5px;
  background-color: #19294d;
  font-size: 14px;
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.cricketSpecificUi .cricketF {
  width: 28px;
  height: 25px;
  border-radius: 5px;
  background-color: #087583;
  font-size: 14px;
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

/* ChatBox.css */
.chat-container {
  position: relative;
}

.chatBox {
  cursor: pointer;
  position: fixed;
  bottom: 10%;
  right: 20px;
  background-color: #a886cd;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.chatBox img {
  width: 25px;
  height: 25px;
  transition: all 0.3s ease-in-out;
  animation: tilt-shaking 0.3s infinite;
}

.whitespaceNowrap {
  white-space: nowrap !important;
  text-align: left !important;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0eg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.images-container {
  position: fixed;
  bottom: 18%;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(20px);
  cursor: pointer;
  z-index: 99;
}

.images-container.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.images-container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  transition: all 0.5s ease-in-out;
}

.instaIcon {
  width: 25px;
  height: 25px;
}

.iframeWrapper {
  /* height: 50vh; */
  height: calc(100dvh - 100px);
}

@media(max-width: 991px) {
  .iframeWrapper {
    height: calc(100dvh - 68px);
    padding-bottom: 65px
  }

}

.accountPageWRapper {
  width: 100% !important;
  border-radius: 0 !important;
}

.accountPageWRapper .accountHead {
  background: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%) !important;
  font-size: 25px !important;
  color: white !important;
  border-radius: 0 !important;
}

.accountLink {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #2789ce !important;
}

/* .mobile-menu .active {
  background-color: #007bff; 
  color: #fff; 
} */

.sportHeader {
  background: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%) !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: white !important;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sportLi {
  background-color: white !important;
  padding: 12px 12px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  border-bottom: 1px solid rgb(196, 192, 192);
}

.sportAnchor {
  color: #2789ce !important;
}

.sportsli {
  border: 1px solid #4b4b4b;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 25%;
}

.sportsUl {
  background-color: #14213d;
  height: 80px;
}

.sportsAnchor {
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: white;
  font-weight: 500;
  text-align: center;
  flex-direction: column;
}

.dream-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.mainDiv {
  margin-top: 100px;
  /* overflow: hidden; */
  height: calc(100dvh - 100px);
}

@media (max-width:991px) {
  .mainDiv {
    margin-top: 68px;
    height: calc(100dvh - 100px);
  }
}

.scrollPart {
  height: 100%;
  overflow-y: auto;
}

.sideBarWrapper {
  /* position: fixed;
  top: 100px;
  width: 100%; */
}

.scoretabcontent {
  height: calc(100dvh - 100px);
}

@media (max-width: 991px) {
  .scoretabcontent {
    height: calc(100dvh - 118px);
  }
}


.fontSize17 {
  font-size: 17px !important;
  font-weight: 700 !important;
  color: #2789ce !important;
}
.fontSize15 {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.back-row {
  background-color: #beddf4 !important;
  color: #3b5160;
  font-size: 10px;
}

.lay-row {
  background-color: #f3dce2 !important;
  color: #3b5160;
  font-size: 10px;
}

.profitLossButton {
  color: #fff;
  background-color: #0d6efd;
  padding: 3px 12px;
  border: none;
}