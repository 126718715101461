/* HTML: <div className="loader"></div> */
.loaderWrapper{
  position: fixed;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0,0,0,0.5);
  opacity: .8;
  z-index: 99999;
  top: 0;
  left: 0;
}
 .textRed{
  color: red !important;
  font-weight: 700;
 }

 .textGreen{
  color: green !important;
  font-weight: 700;
 }